<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-toolbar-title class="pr-8">Pubhub Data</v-toolbar-title>

          <PubhubQueryDialog v-if="showFilter" @catalog_changing="clearSearch">
          </PubhubQueryDialog>

          <div v-for="filter in currentFilters()" v-bind:key="filter.name">
            <v-chip class="ma-2">
              {{ filter.value }}
            </v-chip>
          </div>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-progress-linear
            :active="isLoading()"
            :indeterminate="true"
            absolute
            bottom
            color="blue-grey darken-3"
          ></v-progress-linear>
        </v-toolbar>

        <v-card>
          <v-data-table
            must-sort
            :headers="visibleHeaders"
            :search="search"
            :items="getCatalog()"
            :items-per-page="25"
            :sort-by="['id']"
            :sort-desc="[true]"
            class="elevation-1"
            dense
          >
            <template v-slot:item.file_size="{ item }">
              {{ formatNum(item.file_size) }}
            </template>

            <template v-slot:item.record_count="{ item }">
              {{ formatNum(item.record_count) }}
            </template>

            <template
              v-if="showBatchLink"
              v-slot:item.import_batch_id="{ item }"
            >
              <router-link
                v-if="item.import_batch_id"
                :to="{
                  name: 'BatchInfo',
                  params: { id: item.import_batch_id },
                }"
              >
                {{ item.import_batch_id }}
              </router-link>
            </template>
            <template v-else>
              {{ item.import_batch_id }}
            </template>

            <template v-slot:item.download="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    text
                    icon
                    v-on="on"
                    @click="downloadFile(item.id)"
                  >
                    <v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </template>
                <span>Download File</span>
              </v-tooltip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-row>
                <PubhubUpdateStatus :id="item.id"></PubhubUpdateStatus>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      icon
                      v-on="on"
                      class="d-inline"
                      @click="OnShowRawJson(item)"
                    >
                      <v-icon d-inline>mdi-card-search-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>View Raw Data</span>
                </v-tooltip>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PubhubQueryDialog from "@/components/pubhub/PubhubQueryDialog";
import PubhubUpdateStatus from "@/components/pubhub/PubhubUpdateStatus";
import { formatMoney, formatNumber } from "@/lib/utils";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import api from "@/services/api";
import { toISO } from "@/lib/utils";
import RawJson from "@/components/utils/RawJson";

export const ID_HEADER = "ID";
export const SOURCE_HEADER = "Source";
export const TYPE_HEADER = "Type";
export const KEY_HEADER = "S3 Key";
export const DOWNLOAD_HEADER = "\u21B4";
export const PUBLISHED_DATE_HEADER = "Published";
export const BATCH_ID_HEADER = "Batch ID";
export const SIZE_HEADER = "Size (Bytes)";
export const RECORD_COUNT_HEADER = "Record Count";
export const ACTIONS_HEADER = "Actions";

export default {
  name: "PubhubControl",

  components: {
    PubhubQueryDialog,
    PubhubUpdateStatus,
  },

  data() {
    return {
      search: "",
      headers: [
        { text: ID_HEADER, value: "id" },
        { text: SOURCE_HEADER, value: "data_source" },
        { text: TYPE_HEADER, value: "data_type" },
        { text: KEY_HEADER, value: "s3_key" },
        {
          text: DOWNLOAD_HEADER,
          value: "download",
          sortable: false,
          align: "center",
        },
        { text: PUBLISHED_DATE_HEADER, value: "created" },
        { text: BATCH_ID_HEADER, value: "import_batch_id" },
        { text: SIZE_HEADER, value: "file_size" },
        { text: RECORD_COUNT_HEADER, value: "record_count" },
        { text: ACTIONS_HEADER, value: "actions" },
      ],
    };
  },

  props: {
    displayColumns: {
      type: Array,
      default: () => [],
    },

    showFilter: {
      type: Boolean,
      default: true,
    },

    showBatchLink: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    isLoading() {
      return this.$store.getters["pubhub/isLoading"];
    },

    getCatalog() {
      return this.$store.getters["pubhub/getCatalog"];
    },

    clearSearch() {
      this.search = "";
    },

    formatSales(value) {
      if (value !== "") {
        return formatMoney(value);
      } else {
        return "N/A";
      }
    },

    formatNum(value) {
      if (value !== "") {
        return formatNumber(value);
      } else {
        return "N/A";
      }
    },

    formatDT(value) {
      if (value !== "") {
        return format(parseISO(toISO(value)), "MM-dd-yyyy");
      } else {
        return "N/A";
      }
    },

    downloadFile(id) {
      //console.log(`About to download ${id}`);

      api
        .getDataCatalogByID(id)
        .then((response) => {
          this.saveAs(response.data.archive_url);
        })
        .catch((error) => {
          const notification = {
            type: "error",
            message: error.message,
            details: error.data,
          };

          this.$store.dispatch("notification/add", notification);
        });
    },

    saveAs(archive_url) {
      let link = document.createElement("a");
      link.href = archive_url;
      document.body.appendChild(link); // Firefox requires the link to be in the body
      link.download = true;
      link.click();
      document.body.removeChild(link); // remove the link when done
    },

    currentFilters() {
      let filterOptions = this.$store.getters["pubhub/getFilterOptions"];

      if (filterOptions) {
        return Object.keys(filterOptions).map((obj) => {
          return { name: obj, value: filterOptions[obj] };
        });
      } else {
        return [];
      }
    },

    OnShowRawJson(item) {
      this.$modal.show(
        RawJson,
        {
          title: `Data Catalog ID: ${item.id}`,
          code: JSON.stringify(item, null, 2),
        },
        {
          width: "60%",
          height: "80%",
        }
      );
    },
  },

  computed: {
    visibleHeaders() {
      if (this.displayColumns.length === 0) {
        return this.headers;
      }

      return this.headers.filter((item) => {
        return this.displayColumns.includes(item.text);
      });
    },
  },

  beforeDestroy() {
    this.$store.dispatch("pubhub/clearCatalog");
  },
};
</script>

<style lang="css"></style>
