<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn text icon v-on="{ ...tooltip, ...dialog }">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Update record status</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Update Status</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select
                label="Status"
                v-model="status"
                :items="items"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue darken-1" text :disabled="updating" @click="clear"
          >Clear</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="updating"
          @click="closeDialog"
          >Close</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          :loading="updating"
          @click="updateStatus"
          >Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      status: "",
      items: [
        { text: "Available", value: "A" },
        { text: "Bad Data", value: "B" },
        { text: "Rejected", value: "R" },
      ],
    };
  },

  methods: {
    closeDialog() {
      this.dialog = false;
    },

    clear() {
      this.status = "";
    },

    updateStatus() {
      if (!this.status || this.status === "A") {
        this.closeDialog();
      }

      this.$store
        .dispatch("pubhub/updateStatus", {
          id: this.id,
          status: this.status,
        })
        .then(() => {
          this.$store.dispatch("notification/add", {
            type: "success",
            message: "Status updated on catalog " + this.id,
          });
          this.$emit("ph-status-updated", this.id, this.status);
          this.closeDialog();
        });
    },
  },

  computed: {
    ...mapState("pubhub", ["updating"]),
  },
};
</script>

<style></style>
