<template>
  <div>
    <v-toolbar>
      <v-toolbar-title class="pr-8">Pending Files</v-toolbar-title>
      <FileUploadDialog
        :pipelineSlug="pipelineSlug"
        v-on:file-uploaded="addToPendingFiles"
      >
      </FileUploadDialog>

      <v-progress-linear
        :active="isLoading"
        :indeterminate="true"
        absolute
        bottom
        color="blue-grey darken-3"
      ></v-progress-linear>
    </v-toolbar>

    <v-card>
      <v-data-table
        :headers="pendingFileHeaders"
        :items="pendingFiles"
        :items-per-page="5"
        class="elevation-1"
        hide-default-footer
        disable-pagination
        dense
      >
        <template v-slot:item.name="{ item }">
          <a
            :href="getPendingFileURL(item.name)"
            @click.stop.prevent="downloadPendingFile(item.name)"
            >{{ item.name }}</a
          >
        </template>

        <template v-slot:item.status_icon="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-if="hasError(item)" color="error" text icon v-on="on">
                <v-icon>mdi-alert</v-icon>
              </v-btn>
            </template>
            <span>{{ getErrorMsg(item) }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.size="{ item }">
          {{ formatNumber(item.size) }}
        </template>

        <template v-slot:item.mod_time="{ item }">
          {{ formatDT(item.mod_time) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <FilenameChangeDialog
            class="bottom-right"
            :oldFileName="item.name"
            :pipelineSlug="pipelineSlug"
            v-on:filename-updated="updatePendingFileName"
          ></FilenameChangeDialog>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn text icon v-on="on" @click="deletePendingFile(item.name)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <span>Delete file</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import confirm from "@/components/utils/Confirm";
import FilenameChangeDialog from "@/components/upload/FilenameChangeDialog";
import FileUploadDialog from "@/components/upload/FileUploadDialog";
import api from "@/services/api.js";
import { formatDateTime, formatNumber, formatMoney } from "@/lib/utils";

export default {
  components: {
    confirm,
    FilenameChangeDialog,
    FileUploadDialog,
  },

  props: {
    pipelineSlug: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      pendingFileHeaders: [
        { text: "File name", sortable: true, value: "name" },
        { text: "", sortable: false, value: "status_icon" },
        { text: "Size (Bytes)", sortable: true, value: "size" },
        { text: "Date/time", sortable: true, value: "mod_time" },
        { text: "Actions", sortable: false, value: "actions" },
      ],
    };
  },

  methods: {
    getPendingFileURL(fileName) {
      return `${process.env.VUE_APP_URL}/api/upload/${this.pipelineSlug}/pending-file/${fileName}`;
    },

    downloadPendingFile(fileName) {
      api.getUploadFile(this.pipelineSlug, fileName).then((response) => {
        this.forceFileDownload(response, fileName);
      });
    },

    updatePendingFileName(oldFileName, newFileName) {
      for (let i = 0; i < this.pendingFiles.length; i++) {
        const f = this.pendingFiles[i];
        if (f.name == oldFileName) {
          f.name = newFileName;
          break;
        }
      }
    },

    addToPendingFiles(fileName, fileSize, fileDateTime) {
      // if the file already exists, update the values
      for (let i = 0; i < this.files.length; i++) {
        const f = this.files[i];
        if (f.name == fileName) {
          (f.size = fileSize), (f.mod_time = fileDateTime);
          return;
        }
      }

      // if not in the list, then add it to the array
      this.files.push({
        name: fileName,
        size: fileSize,
        mod_time: fileDateTime,
      });
    },

    deletePendingFile(fName) {
      let pipeline = this.pipelineSlug;

      if (/\.mbox$/.test(fName)) {
        const notification = {
          type: "warning",
          message: "You cannot delete a .mbox file",
          details: "",
          timeout: 3000,
        };

        this.$store.dispatch("notification/add", notification);
      }

      this.$refs.confirm
        .open("Delete", `Are you sure want to delete: ${fName}`, {
          color: "#C62828",
          width: 500,
        })
        .then((confirm) => {
          if (confirm) {
            let payload = { pipelineSlug: pipeline, fileName: fName };
            this.$store.dispatch("uploadDetails/deleteFile", payload);
          }
        });
    },

    forceFileDownload(response, fileName) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    },

    formatSales(value) {
      return formatMoney(value);
    },

    formatNumber(value) {
      return formatNumber(value);
    },

    formatDT(value) {
      return formatDateTime(value);
    },

    hasError(fileInfo) {
      if (fileInfo && fileInfo.error) {
        return !!fileInfo.error.message;
      } else {
        return false;
      }
    },

    getErrorMsg(fileInfo) {
      if (fileInfo && fileInfo.error && fileInfo.error.message) {
        let msg = fileInfo.error.message;
        let hint = fileInfo.error.hint;
        return `${msg} (hint: ${hint})`;
      } else {
        return "";
      }
    },
  },

  computed: {
    pendingFiles() {
      let x = this.$store.getters["uploadDetails/getDetails"];
      return x ? x.pending_files : [];
    },

    isLoading() {
      return this.$store.getters["uploadDetails/isLoading"];
    },
  },
};
</script>

<style></style>
