<template>
  <div>
    <!-- detail panel -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title
                class="pt-5 pl-1 display-1 text-capitalize font-weight-bold"
                >{{ displayName }}
                <RefreshControl
                  class="pt-10 pl-7"
                  :loading="isLoading"
                  :interval="60"
                  v-on:refresh-now="refreshData"
                ></RefreshControl>
                <ProcessDialog
                  :pipelineSlug="pipelineSlug"
                  class="pt-10 pl-7"
                ></ProcessDialog>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn text icon v-on="on" @click="gotoConfig">
                      <v-icon color="grey">mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit configuration</span>
                </v-tooltip>
              </v-toolbar-title>
            </v-toolbar>

            <div class="pt-1">
              <span class="pl-5 font-weight-bold">Status:</span>

              <span
                v-if="status == 'error'"
                class="pl-5 font-weight-regular text-capitalize error--text"
                >{{ status }}
              </span>

              <span v-else class="pl-5 font-weight-regular text-capitalize">
                {{ status }}
              </span>

              <span class="errorInfo" v-if="hasError">
                <span v-if="isErrorLink">
                  <a
                    :href="details.last_error"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    details
                  </a>
                </span>
                <span v-else>({{ details.last_error }})</span>
              </span>
            </div>

            <div>
              <span class="pl-5 font-weight-bold">Last run:</span>
              <span class="pl-5 font-weight-regular"
                >{{ lastRun }} {{ lastRunInterval }}
              </span>
            </div>

            <div class="pb-5">
              <span class="pl-5 font-weight-bold">Next run:</span>
              <span class="pl-5 font-weight-regular"
                >{{ nextRun }} {{ nextRunInterval }}
              </span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- pending files -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <PendingFilesControl :pipelineSlug="pipelineSlug">
          </PendingFilesControl>
        </v-col>
      </v-row>
    </v-container>

    <PubhubControl :displayColumns="pubhubHeaders" :showFilter="false">
    </PubhubControl>
  </div>
</template>

<script>
import { formatDateTime } from "@/lib/utils";
import PendingFilesControl from "@/components/upload/PendingFilesControl";
import PubhubControl, {
  ID_HEADER,
  KEY_HEADER,
  DOWNLOAD_HEADER,
  PUBLISHED_DATE_HEADER,
  RECORD_COUNT_HEADER,
  BATCH_ID_HEADER,
  SIZE_HEADER,
  ACTIONS_HEADER,
} from "@/components/pubhub/PubhubControl";
import RefreshControl from "@/components/utils/RefreshControl";
import ProcessDialog from "@/components/upload/ProcessDialog";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { toISO } from "@/lib/utils";

export default {
  components: {
    PendingFilesControl,
    PubhubControl,
    RefreshControl,
    ProcessDialog,
  },

  created() {
    this.pubhubHeaders = [
      ID_HEADER,
      KEY_HEADER,
      DOWNLOAD_HEADER,
      PUBLISHED_DATE_HEADER,
      BATCH_ID_HEADER,
      RECORD_COUNT_HEADER,
      SIZE_HEADER,
      ACTIONS_HEADER,
    ];
  },

  data() {
    return {
      // details: null,
      dialog: false,
      pagination: { sortBy: "id", descending: true, rowsPerPage: -1 },
    };
  },

  props: {
    pipelineSlug: {
      type: String,
      required: true,
    },
  },

  methods: {
    getPubhubData() {
      let params = {
        dataSource: this.pipelineSlug,
        fromDate: "45 days",
        dataType: "source data",
      };

      this.$store.dispatch("pubhub/getCatalog", params);
    },

    refreshData() {
      this.$store.dispatch("notification/clear");
      this.$store.dispatch("uploadDetails/getDetails", this.pipelineSlug);
      this.getPubhubData();
    },

    pipelineDisabled() {
      if (this.details) {
        return this.details.next_run === "disabled";
      }

      return true;
    },

    gotoConfig() {
      this.$router.push({ name: "UploadConfig" });
    },
  },

  computed: {
    isLoading() {
      return this.$store.getters["uploadDetails/isLoading"];
    },

    details() {
      return this.$store.getters["uploadDetails/getDetails"];
    },

    status() {
      return this.details ? this.details.status : "";
    },

    displayName() {
      return this.details ? this.details.display_name : "";
    },

    lastRun() {
      try {
        return formatDateTime(this.details.last_run);
      } catch {
        return "";
      }
    },

    lastRunInterval() {
      try {
        if (this.details && this.details.last_run) {
          return (
            "(" +
            formatDistanceToNow(parseISO(toISO(this.details.last_run))) +
            ")"
          );
        } else {
          return "";
        }
      } catch {
        return "";
      }
    },

    nextRun() {
      try {
        return formatDateTime(this.details.next_run);
      } catch {
        return "";
      }
    },

    nextRunInterval() {
      try {
        return (
          "(" +
          formatDistanceToNow(parseISO(toISO(this.details.next_run)), {
            includeSeconds: true,
          }) +
          ")"
        );
      } catch {
        return "";
      }
    },

    hasError() {
      return this.details && this.details.last_error;
    },

    isErrorLink() {
      if (this.details && this.details.last_error) {
        let re = new RegExp("^https", "i");
        return re.test(this.details.last_error);
      } else {
        return false;
      }
    },
  },

  mounted() {
    this.refreshData();
  },
};
</script>

<style scoped>
.errorInfo {
  padding-left: 2em;
}
</style>
