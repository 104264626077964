<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn text icon v-on="{ ...tooltip, ...dialog }">
            <v-icon>mdi-rename-box</v-icon>
          </v-btn>
        </template>
        <span>Rename file</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Rename File</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="subtitle-1">
                <span class="font-weight-bold">Current file name: </span>
                {{ oldFileName }}
              </div>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="New file name"
                autofocus
                required
                spellcheck="false"
                v-model="newFileName"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
        <v-btn color="blue darken-1" text @click="renameFile">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/services/api.js";

export default {
  name: "FilenameChangeDialog",

  data: () => ({
    dialog: false,
    newFileName: "",
  }),

  props: {
    oldFileName: {
      type: String,
      required: true,
    },
    pipelineSlug: {
      type: String,
      required: true,
    },
  },

  methods: {
    renameFile() {
      api
        .renameUploadFile(this.pipelineSlug, this.oldFileName, this.newFileName)
        .then(() => {
          this.dialog = false;
          this.$emit("filename-updated", this.oldFileName, this.newFileName);
        });
    },
  },

  mounted() {
    this.newFileName = this.oldFileName;
  },
};
</script>

<style scoped></style>
