<template>
  <span>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary darken-1"
          text
          @click="emitRefresh"
          icon
          v-on="on"
        >
          <v-icon>mdi-refresh</v-icon> {{ timeDisplay }}</v-btn
        >
      </template>
      <span>Refresh data now</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  _intervalID: null,

  props: {
    interval: {
      type: Number,
      default: 60,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showCountDown: false,
      ticks: 0,
    };
  },

  methods: {
    tick() {
      this.ticks++;
      this.showCountDown = this.timeRemaining <= 5;
      if (this.ticks >= this.interval) {
        this.emitRefresh();
      }
    },

    emitRefresh() {
      this.$emit("refresh-now");
      this.ticks = 0;
      this.showCountDown = false;
    },
  },

  computed: {
    timeRemaining() {
      return this.interval - this.ticks;
    },

    timeDisplay() {
      if (this.showCountDown) {
        return this.timeRemaining;
      } else {
        return "";
      }
    },
  },

  mounted() {
    this._intervalID = setInterval(this.tick, 1000);
  },

  beforeDestroy() {
    if (this._intervalID) {
      clearInterval(this._intervalID);
    }
  },
};
</script>

<style></style>
