<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <template v-slot:activator="{ on }">
      <v-btn class="ma-2" text v-on="on" color="primary">
        <v-icon>mdi-filter</v-icon> Filters
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Pubhub Filters</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-select
                label="Data source"
                v-model="dataSource"
                :items="getDataSources"
                item-text="ds"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Data Type"
                v-model="dataType"
                :items="getDataTypes"
                item-text="dt"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-select
                :items="intervalItems"
                v-model="fromDate"
                label="Date/time filter"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-text-field
                label="Batch ID"
                spellcheck="false"
                :rules="batchIDRules"
                v-model="importBatchID"
                hint="Supports regular expressions (e.g. 60-5[123])"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="clearFilters">Clear</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
        <v-btn color="blue darken-1" text @click="getCatalog">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { isValidRegExp } from "@/lib/utils";

export default {
  data() {
    return {
      dialog: false,
      dataSource: "",
      dataType: "",
      importBatchID: "",
      fromDate: "",
      intervalItems: [
        "10 minutes",
        "30 minutes",
        "1 hour",
        "8 hours",
        "12 hours",
        "Today",
        "1 day",
        "This week",
        "1 week",
        "2 weeks",
        "This month",
        "1 month",
        "This quarter",
        "3 months",
        "This year",
        "1 year",
      ],
      batchIDRules: [
        (v) =>
          /^\d+-.+/.test(v) ||
          v === "" ||
          'Expression must include a pipeline ID and a "-"',
        (v) => isValidRegExp(v) || "Must be a valid regular expression",
      ],
    };
  },

  methods: {
    closeDialog() {
      this.dialog = false;
    },

    clearFilters() {
      this.dataSource = "";
      this.dataType = "";
      this.importBatchID = "";
      this.fromDate = "";
    },

    getCatalog() {
      this.$emit("catalog_changing");

      const options = {};
      // fromDate is not optional... it will always
      // be set (it defaults to now() - 30 days)
      options["fromDate"] = this.fromDate;

      if (this.dataType) {
        options["dataType"] = this.dataType;
      }

      if (this.dataSource) {
        options["dataSource"] = this.dataSource;
      }

      if (this.importBatchID) {
        options["importBatchID"] = this.importBatchID;
      }

      this.$store
        .dispatch("pubhub/getCatalog", options)
        .then(this.closeDialog());
    },
  },

  computed: {
    getValidDataTypeList() {
      return this.data;
    },

    getDataSources() {
      let self = this;

      if (this.dataType) {
        let dataTypes = this.$store.getters["pubhub/getDataTypes"];

        let filteredList = dataTypes.filter(
          (obj) => obj && obj.dt === self.dataType
        )
          ? dataTypes
          : null;

        if (filteredList && filteredList.length > 0) {
          return filteredList[0].ds;
        } else {
          return [];
        }
      } else {
        return this.$store.getters["pubhub/getDataSources"];
      }
    },

    getDataTypes() {
      let self = this;
      if (this.dataSource) {
        let dataSources = this.$store.getters["pubhub/getDataSources"];

        let filteredList = dataSources.filter(
          (obj) => obj && obj.ds === self.dataSource
        )
          ? dataSources
          : null;

        if (filteredList && filteredList.length > 0) {
          return filteredList[0].dt;
        } else {
          return [];
        }
      } else {
        return this.$store.getters["pubhub/getDataTypes"];
      }
    },
  },

  mounted() {
    this.$store.dispatch("pubhub/getDataSources");

    let filterOptions = this.$store.getters["pubhub/getFilterOptions"];

    this.dataSource = filterOptions.dataSource;
    this.dataType = filterOptions.dataType;
    this.batchID = filterOptions.batchID;
    this.fromDate = filterOptions.fromDate;
  },
};
</script>

<style></style>
