<template>
  <v-dialog v-model="showDialog" persistent max-width="700px">
    <template v-slot:activator="{ on }">
      <v-btn class="ma-2" text v-on="on" color="green">
        <v-icon>mdi-upload</v-icon> Upload
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Upload</v-toolbar-title>
      </v-toolbar>

      <v-progress-linear
        v-model="percentCompleted"
        color="red darken-3"
        :active="uploading"
        :height="10"
      ></v-progress-linear>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-file-input
                show-size
                label="File input"
                v-model="fileSelected"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="uploading"
          color="blue darken-1"
          text
          @click="closeDialog"
          >Close</v-btn
        >
        <v-btn
          :loading="uploading"
          color="blue darken-1"
          text
          @click="uploadFile"
          >Upload</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FileUploadDialog",

  data: () => ({
    showDialog: false,
    fileSelected: null,
    percentCompleted: 0,
  }),

  props: {
    pipelineSlug: {
      type: String,
      required: true,
    },
  },

  methods: {
    uploadFile() {
      if (!this.fileSelected) {
        this.closeDialog();
      }

      let data = new FormData();
      data.append("file", this.fileSelected);

      let payload = {
        pipelineSlug: this.pipelineSlug,
        fileSelected: this.fileSelected,
        data: data,
        progressCallback: this.onProgress,
      };

      let f = this.closeDialog;
      this.$store.dispatch("uploadDetails/uploadFile", payload).then(() => {
        f();
      });
    },

    closeDialog() {
      this.fileSelected = null;
      this.showDialog = false;
      this.percentCompleted = 0;
    },

    onProgress(progressEvent) {
      this.percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
  },

  computed: {
    uploading() {
      return this.$store.getters["uploadDetails/isUploading"];
    },
  },
};
</script>

<style scoped></style>
