var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',[_c('v-toolbar-title',{staticClass:"pr-8"},[_vm._v("Pubhub Data")]),(_vm.showFilter)?_c('PubhubQueryDialog',{on:{"catalog_changing":_vm.clearSearch}}):_vm._e(),_vm._l((_vm.currentFilters()),function(filter){return _c('div',{key:filter.name},[_c('v-chip',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(filter.value)+" ")])],1)}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-progress-linear',{attrs:{"active":_vm.isLoading(),"indeterminate":true,"absolute":"","bottom":"","color":"blue-grey darken-3"}})],2),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"must-sort":"","headers":_vm.visibleHeaders,"search":_vm.search,"items":_vm.getCatalog(),"items-per-page":25,"sort-by":['id'],"sort-desc":[true],"dense":""},scopedSlots:_vm._u([{key:"item.file_size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNum(item.file_size))+" ")]}},{key:"item.record_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNum(item.record_count))+" ")]}},(_vm.showBatchLink)?{key:"item.import_batch_id",fn:function(ref){
var item = ref.item;
return [(item.import_batch_id)?_c('router-link',{attrs:{"to":{
                name: 'BatchInfo',
                params: { id: item.import_batch_id },
              }}},[_vm._v(" "+_vm._s(item.import_batch_id)+" ")]):_vm._e()]}}:{key:"default",fn:function(undefined){return [_vm._v(" "+_vm._s(_vm.item.import_batch_id)+" ")]}},{key:"item.download",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","icon":""},on:{"click":function($event){return _vm.downloadFile(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download File")])])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-row',[_c('PubhubUpdateStatus',{attrs:{"id":item.id}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"d-inline",attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.OnShowRawJson(item)}}},on),[_c('v-icon',{attrs:{"d-inline":""}},[_vm._v("mdi-card-search-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("View Raw Data")])])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }