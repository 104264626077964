var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',[_c('v-toolbar-title',{staticClass:"pr-8"},[_vm._v("Pending Files")]),_c('FileUploadDialog',{attrs:{"pipelineSlug":_vm.pipelineSlug},on:{"file-uploaded":_vm.addToPendingFiles}}),_c('v-progress-linear',{attrs:{"active":_vm.isLoading,"indeterminate":true,"absolute":"","bottom":"","color":"blue-grey darken-3"}})],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.pendingFileHeaders,"items":_vm.pendingFiles,"items-per-page":5,"hide-default-footer":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.getPendingFileURL(item.name)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadPendingFile(item.name)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.status_icon",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.hasError(item))?_c('v-btn',_vm._g({attrs:{"color":"error","text":"","icon":""}},on),[_c('v-icon',[_vm._v("mdi-alert")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getErrorMsg(item)))])])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.size))+" ")]}},{key:"item.mod_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDT(item.mod_time))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('FilenameChangeDialog',{staticClass:"bottom-right",attrs:{"oldFileName":item.name,"pipelineSlug":_vm.pipelineSlug},on:{"filename-updated":_vm.updatePendingFileName}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.deletePendingFile(item.name)}}},on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete file")])])]}}])})],1),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }