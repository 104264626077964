<template>
  <v-dialog v-model="dialog" persistent max-width="80%">
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn text icon v-on="{ ...tooltip, ...dialog }">
            <v-icon color="success">mdi-play-network</v-icon>
          </v-btn>
        </template>
        <span>Run publisher now</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Publish File</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <div class="pb-2 title line">Output:</div>
              <p class="pt-6 log-display">{{ logfile }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="copy()"
          ><v-icon>mdi-content-copy</v-icon>Copy to clipboard
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    pipelineSlug: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
  }),

  watch: {
    dialog(newValue, oldValue) {
      if (newValue) {
        this.$store.dispatch("uploadDetails/publish", this.pipelineSlug);
      }

      if (oldValue) {
        this.$store.dispatch(
          "uploadDetails/clearPublishInfo",
          this.pipelineSlug
        );
      }
    },
  },

  methods: {
    copy() {
      navigator.clipboard.writeText(this.logfile).catch((e) => {
        alert("Copy failed: " + e);
      });
    },
  },

  computed: {
    logfile() {
      return this.$store.getters["uploadDetails/getProcessInfo"];
    },
  },
};
</script>

<style scoped>
p.log-display {
  overflow-x: scroll;
  overflow-y: scroll;
  white-space: pre;
  height: 400px;
  font-family: Courier, Monaco, monospace;
}

.line {
  border-bottom: 5px solid grey;
}
</style>
